import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'; // Importez Link
import '../css-styles/articleCard.scss';
import Image1 from "../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe84.webp";


const ArticleCard = ({ title, summary, imageUrl, link }) => {
  return (
    <div className="article-card">
      <Link to={link} className="article-link"> {/* Utilisez Link ici */}
        <img src={imageUrl} alt={title} />
        <div className="article-content">
          <p>{summary}</p>
        </div>
      </Link>
    </div>
  );
};

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ArticleCard;