import React, { useState } from "react";
import axios from "axios";
import { FaCheckCircle } from 'react-icons/fa'; // Importation de l'icône de confirmation
import "../css-styles/formulaire.scss";

const Formulaire = () => {
  const [formData, setFormData] = useState({
    formule: "",
    name: "",
    firstname: "",
    email: "",
    date: "",
    location: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // Nouvel état pour gérer l'affichage du message

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changement détecté : ${name} = ${value}`);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Soumission du formulaire avec les données :", formData);

    const emailData = {
      ...formData
    };

    axios.post("https://laboinspi-wedding.fr/api/formulaires/envoyer-formulaire", emailData)
      .then((response) => {
        console.log("Réponse du serveur :", response.data);
        setIsSubmitted(true); // Mettez à jour l'état pour afficher le message de confirmation
      })
      .catch((error) => {
        console.error("Erreur lors de la soumission du formulaire :", error);
        setIsSubmitted(false); // Optionnel, gérer l'affichage d'un message d'erreur
      });
  };

  const formuleOptions = [
    "Formule personnalisée",
    "Formule coaching",
    "Formule préparatifs",
    "Formule clé en main",
    "Questions et Rendez-vous"
  ];

  return (
    <div className="form-container">
      <form className="form-contact" onSubmit={handleSubmit}>
        <div className="formule-options">
          <label htmlFor="formule">Choix de formule:</label>
          <select
            name="formule"
            value={formData.formule}
            onChange={handleChange}
          >
            <option value="">-- Sélectionnez une formule --</option>
            {formuleOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="form-input">
          <label htmlFor="name">Nom:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <div className="form-input">
          <label htmlFor="firstname">Prénom:</label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
          />
        </div>

        <div className="form-input">
          <label htmlFor="email">E-mail:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="form-input">
          <label htmlFor="date">Date du mariage:</label>
          <input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
        </div>

        <div className="form-input">
          <label htmlFor="location">Lieu du mariage:</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </div>

        <div className="form-contact-message">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>

        <button type="submit" className="submit-button">
          Envoyer
        </button>
      </form>
      {isSubmitted && (
        <div className="confirmation-message">
          <FaCheckCircle /> {/* Icône de confirmation */}
          <span>Le formulaire a bien été envoyé. Merci !</span>
        </div>
      )}
    </div>
  );
};

export default Formulaire;