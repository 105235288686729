import React from 'react';
import '../css-styles/blog.scss';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

import ArticleCard from '../components/articleCard';

import Image1 from "../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe84.webp";
import Image2 from "../images/Articles/article 2/Ceremonie Laique plage couple.jpg";

const Blog = () => {

    const articles = [
        {
          id: 1,
          title: "Mon parcours",
          summary: "Salut, moi c’est Marine, ancienne étudiante en communication et en événementiel que rien ne prédestinait à créer son entreprise.",
          imageUrl: Image1,
          link: "/Mon-parcours-wedding-designer",
        },
        {
          id: 2,
          title: "Le métier de wedding designer",
          summary: "Wedding planner, wedding designer, wedding officiant… pas facile de s’y retrouver quand on débarque dans l’univers du mariage, pas vrai ?...",
          imageUrl: Image2,
          link: "/Metier-wedding-designer",
        }
        
        // ... d'autres articles ...
      ];
  return (
    <div className="home-galerie">
      <div className="home-container">
      <Helmet>
        <title>Tendances et adresses à connaître pour votre mariage - Le Labo’ Inspi</title>
        <meta name="description" content="Le Labo’ Inspi vous transporte dans l’univers du mariage en Loire-Atlantique (44) et Vendée (85) et vous donne ses astuces et conseils de wedding designer et d’organisatrice de mariage. "></meta>
        <link rel="canonical" href="https://laboinspi-wedding.fr/blog" />
      </Helmet>
        <Header />
        <div className='title-blog-text'>
          <h1>Le Labo’ Inspi vous transporte dans l’univers du mariage en Loire-Atlantique</h1>
          <h2 className='galerie-subtitle'>Retrouvez toutes les tendances et adresses à connaître pour votre mariage</h2>
          <p className='blog-textdescrition'> J’ai décidé d’ouvrir le blog du Labo’ Inspi pour aider toutes les futures mariées (et futurs mariés) à choisir leur décoration de mariage mais pas que… lieu de réception, photographe ou encore animations originales, <br>
          </br>je vous livre mes coups de coeur et les tendances à suivre. De quoi vous donner toutes les clés pour un mariage réussi ! 
          </p>
        </div>

        <div className='articles-container'>
          {articles.map((article) => (
            <ArticleCard
              key={article.id}
              title={article.title}
              summary={article.summary}
              imageUrl={article.imageUrl}
              link={article.link}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Blog;