// Header.js
import React from 'react';
import NavigationBar from './NavigationBar'; // Assurez-vous que le chemin d'importation est correct
import '../css-styles/Header.scss'; // Assurez-vous que le chemin d'importation est correct
import { Link } from 'react-router-dom';
import logo from '../images/icone/LOGO-COMPLET.webp';

const Header = () => {
  return (
    <header className="header">
      <div className='wrapper-header'>
      <Link to="/">
        <img src={logo} className="Laboinspi-logo" alt="Lab-o-inspi-logo" />
      </Link>
      <NavigationBar />
      </div>
      
    </header>
  );
}

export default Header;
