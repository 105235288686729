import React from "react";
import Image from "../images/logo_laboinspi_white.webp";
import "../css-styles/Footer.scss";
import MyMapComponent from "../components/MyMapComponent";
import Image1 from "../images/icone/instagram.webp";
import Image2 from "../images/icone/logo-pinterest.webp";
import Image3 from "../images/icone/logo tiktok.webp";

const Footer = () => {
  const instagramLink = "https://www.instagram.com/le.labo.inspi/";

  return (
    <footer className="footer-reserved">
      <div className="logo-info">
        <img src={Image} className="logo-labo" alt="logo-labo-inspi" />
        <h3 className="rights-reserved">
          {" "}
          © 2023 Le Labo Inspi.<br>
          </br> Tout droits reservés
        </h3>
        <div className="credits-container">
          <h4>Crédits Photo :</h4>
          <p>
            <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2Fsarahdugue_mariagephoto%3Figshid%3DMzRlODBiNWFlZA%253D%253D%26fbclid%3DIwAR0P9rBg6kLP_E0B-_Gj5eWjBsSaOIYk9bry4Bwe8-LHl6fYBKSeYc-v2XQ&h=AT3SG4zfAw0W4q9cqj41-9-QXWudpFjmRmL14wX7y8nVDFGF-jiCeeh2oIl8YKa44gcDmtNNNUsqmeqnulG5rK3lL_XzpkYLmeQxSAxLgSHoJpCkx6RYwExocwVtH5_VLHHNf7Eg8bd34hxCYXWcEw">
              Sarah Dugué
            </a>
            {" / "}
            <a href="https://morganecaulle.com/">
              Morgane Caulle
            </a>
            {" / "}
            <a href="https://www.anaisphotographie.fr/">
              Anaïs Duchesne
            </a>
          </p>
        </div>
        <div className="social-media">
          <a
            href={instagramLink}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <img src={Image1} className="insta-icone" alt="instagram-icone" loading="lazy"/>
          </a>
          <a
            href={"https://pin.it/5zCeBKD"}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Pinterest"
          >
            <img
              src={Image2}
              className="pinterest-icone"
              alt="pinterest-icone" loading="lazy"
            />
          </a>
          <a
            href={"https://www.tiktok.com/@le.labo.inspi"}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Pinterest" 
          >
            <img
              src={Image3}
              className="tiktok-icone"
              alt="pinterest-icone" loading="lazy"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;