import React from "react";
import "../css-styles/Certifications.scss";
import ImageIcone from "../images/icone/BADGE CERTIFI(E) COLE MARIELLA - WEDDING DESIGNER.svg";

const Certifications = () => {
  return (
    <div className="certification-container">
      <h2 className="Link-title">Mes liens</h2>
      <div className="certification-links">
        <a
          href="https://www.agence-mariella.com/post/un-shooting-mariage-dolce-vita-%C3%A0-gu%C3%A9rande"
          rel="dofollow"
          target="_blank"
          
        >
          <img
            className="certification-icone"
            src="https://static.wixstatic.com/media/b921ff_4776ed97d8c54cd8ad44c4e6ef122b7d~mv2.jpg/v1/fill/w_873,h_215,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/LOGO%20PRINCIPAL%20%5BJPG%5D%20HQ.jpg"
            alt="Certifications"
            width="200"
            height="auto"
            loading="lazy"
          />
        </a>
        <a
          href="https://www.mariages.net/decoration-mariage/marine-wedding-designer--e303390"
          rel="dofollow"
          title="Mariages.net"
          target="_blank"
        >
          <img
            className="certification-icone2"
            alt="Mariages.net"
            src="https://www.mariages.net/images/sellos/label-partenaire--gg303390.png"
            style={{ borderWidth: 0 }}
            loading="lazy"
          />
        </a>
      </div>
    </div>
  );
};

export default Certifications;
