import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css-styles/NavigationBar.scss';

const NavigationBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const isRouteActive = (route) => location.pathname === route;

  const activeLinkClass = "active-link";

  const isFormuleActive = ['/Coaching', '/Preparatifs', '/Cle-en-main'].some(path => location.pathname.startsWith(path));

  return (
    <nav className="nav-bar">
      <div className="burger-button" onClick={toggleMobileMenu}>
        <div className={`burger-icon ${isMobileMenuOpen ? 'open' : ''}`}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>

      <ul className={`main-menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <li>
        <Link to="/" className={isRouteActive('/') ? activeLinkClass : ''}>Accueil</Link>
        </li>
        <li>
        <span className={isFormuleActive ? activeLinkClass : ''}>Formules</span>
        <div className="sub-menu">
  <Link to="/Coaching" className={`${isRouteActive('/Coaching') ? activeLinkClass : ''} sub-menu-link`}>Coaching</Link>
  <Link to="/Preparatifs" className={`${isRouteActive('/Preparatifs') ? activeLinkClass : ''} sub-menu-link`}>Préparatifs</Link>
  <Link to="/Cle-en-main" className={`${isRouteActive('/Cle-en-main') ? activeLinkClass : ''} sub-menu-link`}>Clé en main</Link>
</div>
        </li>
        <li>
          <Link to="/galerie" className={isRouteActive('/galerie') ? activeLinkClass : null}>Galerie</Link>
        </li>
        <li>
          <Link to="/Apropos" className={isRouteActive('/Apropos') ? activeLinkClass : null}>A propos</Link>
        </li>
        <li>
          <Link to="/Blog" className={isRouteActive('/Blog') ? activeLinkClass : null}>Blog</Link>
        </li>
        <li>
          <Link to="/Contact" className={isRouteActive('/Contact') ? activeLinkClass : null}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavigationBar;
