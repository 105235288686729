import React from 'react';
import { Link } from 'react-router-dom';
import '../css-styles/PreviewFormules.scss';
import Image1 from '../images/formules/Décoration-table-coaching.webp';
import Image1Small from '../images/formules/Décoration-table-coaching-mini.webp';
import Image2 from '../images/formules/Cocktailshootingdolcevita-sarahduguephotographe85_small.webp';
import Image2Small from '../images/formules/Cocktailshootingdolcevita-sarahduguephotographe85.webp';
import Image3 from '../images/formules/salleshootingdolcevita-sarahduguephotographe160_small.webp';
import Image3Small from '../images/formules/salleshootingdolcevita-sarahduguephotographe160.webp';

const PreviewFormules = () => {
  return (
    <div className="formules-wrapper">
      <Link to="/Coaching" className='formule-element'>
        <img
          srcSet={`${Image1} 576w, ${Image1Small} 1050w`}
          sizes="(max-width: 576px) 576px, (min-width: 1050px) 1050px"
          src={Image1Small}
          className="formule-image"
          alt="table-photographie-design"
          loading="lazy"
        />
        <span className="formule-text">Formule Coaching</span>
      </Link>

      <Link to="/Preparatifs" className='formule-element'>
        <img
          srcSet={`${Image2} 576w, ${Image2Small} 1050w`}
          sizes="(max-width: 576px) 576px, (min-width: 1050px) 1050px"
          src={Image2Small}
          className="formule-image"
          alt="cocktail-photographie-design"
          loading="lazy"
        />
        <span className="formule-text">Formule Préparatifs</span>
      </Link>

      <Link to="/Cle-en-main" className='formule-element'>
        <img
          srcSet={`${Image3} 576w, ${Image3Small} 1050w`}
          sizes="(max-width: 576px) 576px, (min-width: 1050px) 1050px"
          src={Image3Small}
          className="formule-image"
          alt="couple-photographie-wedding"
          loading="lazy"
        />
        <span className="formule-text">Formule Clé en main</span>
      </Link>
    </div>
  );
}

export default PreviewFormules;
