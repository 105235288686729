import React from 'react';
import '../css-styles/ArticleContent.scss'; // Assurez-vous d'avoir le CSS approprié
import { Link } from 'react-router-dom';


const SecondArticleContent = () => {
  return (
    <div className='wrapper-article'>
      {/*<img src={Image1} className='photo-long-banner'/>**/}
      <div className="article-content">
      <h1>Découvrez dès maintenant le parcours de votre décoratrice de mariage</h1>
      <h2>Qui est à la tête du Labo’Inspi - Wedding Designer ?</h2>
      <p>Salut, moi c’est Marine, ancienne étudiante en communication et en événementiel que rien ne prédestinait à créer son entreprise. Aujourd’hui, je partage avec vous mon parcours dans l’univers de la communication, ma formation pour devenir Wedding Designer et les débuts de ma création d’entreprise pour vous aider à décorer les plus beaux moments de votre vie. </p>
      
      <h3>Mon parcours dans la communication et l’événementiel</h3>
      <p>Après mon bac général, j’ai cherché à trouver ma voie professionnelle comme beaucoup d’autres… des mois d’introspection à la fac et je me tourne un an plus tard vers les métiers de la communication. En BTS Communication, j’apprends toutes les bases pour construire une stratégie de communication et mettre en place des actions concrètes. Après un stage en relations publiques, le secteur de l’événementiel apparaît à moi comme LA spécialité qui me correspond à ma personnalité : organisée, pro-active et créative. Je continue donc mon chemin en école de communication avec une spécialité événementiel.</p>
      <p>J’expérimente l’événementiel sous différentes formes grâce à mes cours et à mes stages : évènements RP, événements grand public et évènements d’entreprises. Puis je décide de faire une pause d’un an pour voyager à l’étranger : Dublin, Barcelone, la Costa Brava… et puis la pandémie arrive. Le secteur de l’événementiel est à l’arrêt et il faut trouver une alternative pour finir mon cursus. Je fais 1 an d’alternance en communication interne dans une grande entreprise américaine à Paris et j’empoche mon diplôme. </p>
      <p>Le monde de l’événementiel reprenant son souffle (et moi aussi), je rentre dans le monde du travail en tant que chef de projet événementiel en CDD dans une agence d’organisation d’événements. Mais rapidement, le côté créatif me manque. À la fin de mon contrat, je décide d’abord que je souhaite me former au métier de Wedding Planner mais j’ai envie de faire de la décoration d’événements de mariage. Vous imaginez donc ma joie quand je découvre que c’est un métier à part entière et que je peux me former pour devenir Wedding Designer. Mon compte CPF vidé, je commence ma formation à distance avec l’école Mariella.</p>
      
      <h3>Ma formation en tant que Wedding Designer</h3>
      <p>L’école Mariella, dirigée par Virginie, forme ses étudiants aux métiers du mariage depuis 2020 après 10 années d’expérience dans l’organisation d’événements de mariage dans le Sud de la France. La formation « Wedding Designer » à distance et en temps réel que j’ai eu la chance de suivre, c’est près de 90h de cours avec des professionnels du mariage : décoratrice, fleuriste, graphistes, photographe mais aussi des cours sur l’organisation générale du mariage, la communication et la gestion de son entreprise. Aujourd’hui, cette formation est plus longue et est dispensée en e-learning pour plus de flexibilité.</p>
      <p>En cours de wedding design, on apprends à analyser les tendances, définir un ou plusieurs styles pour réaliser votre décoration de mariage. On apprends à combiner les couleurs, les matières, les formes pour créer une véritable harmonie dans la scénographie. En s’inspirant de ce qui existe déjà, on créé un moodboard ou planche d’inspiration qui va nous permettre de vous proposer une première approche visuelle de votre décoration. 
Avec la fleuriste, on apprends le nom des fleurs, leur signification, leur saisonnalité et les combinaisons les plus appropriés selon le style choisi pour votre mariage.
Concernant la papeterie, on parle des typographies, des matières utilisées, des formes et finitions, ainsi que des méthodes d’impression. Les fleurs et la papeterie font partie intégrante de votre décoration de mariage et doivent être en accord avec les objets et le mobilier déco que vous avez choisi. </p>
      
      {/*<img src={Image1} className='photo-long-banner'/>**/}
      <p>Dans cette formation, pour devenir Wedding Designer, on se focalise sur l’esthétisme de votre mariage. Cela passe par les fleurs, la papeterie, le mobilier et la décoration mais aussi les lumières : bougies, guirlande lumineuse, néons, ciel étoilé… sont des éléments qui participent à rendre votre lieu de réception chaleureux et romantique. 

Tout est pris en compte pour créer un véritable univers, une expérience inoubliable pour vous et vos proches. On apprends à réaliser un book déco qui permets de visualiser tous vos espaces décorés, les objets utilisés, les matières, les dimensions et les quantités. De quoi vous donner une idée précise du rendu final avant le jour J. Comme pour la wedding planner, la coordination et la logistique sont aussi des compétences importantes pour exercer ce métier.</p>
      
      <h3>Le lancement de mon entreprise dans la décoration de mariage</h3>
      <p>Pour valider mon diplôme de Wedding Designer et obtenir mon badge, l’école m’a fait passé un examen pratique :  organiser et réaliser un shooting d’inspiration. Le shooting d’inspiration sert à vous inspirer futurs mariés pour votre décoration de mariage. Plusieurs professionnels se réunissent pour apporter chacun leur savoir-faire en tant que prestataires de mariage. Pour organiser ce shooting, j’ai d’abord créer mon moodboard ainsi qu’un premier book déco sur le thème du voyage dans une ambiance bohème, romantique et vintage. Ensuite, j’ai contacté tous les prestataires que je souhaitais pour le shooting : lieu de réception, photographe, fleurs, location mobiliers & déco, papeterie, préparatifs des mariés etc… Plusieurs semaines de recherches, de préparation plus tard, c’est le jour J. On coordonne la journée comme dans un vrai mariage et on sublime les espaces de notre lieu de réception avec la décoration de mariage.</p>
       {/*<div className='Wrapper-photo-banner'>
        <img src={Image3} className='multiple-photo-banner'/>
      <img src={Image4} className='multiple-photo-banner'/>
      <img src={Image5} className='multiple-photo-banner'/>
      </div>**/}
      
      <p>Après ce shooting rempli d’émotions, je reçois mes résultats d’examen et les félicitations de l’équipe Mariella. L’aventure peut commencer… mais par où on commence exactement ?  Après l’excitation ressentie pour la création de ce nouveau projet, vient beaucoup d’incertitude. Suis-je faite pour ça ? Comment me faire connaître et me démarquer ? Beaucoup de questions auxquelles je n’avais pas les réponses. J’ai malgré tout continuer l’aventure : j’ai imaginé un nouveau shooting, j’ai monté une nouvelle équipe de prestataires, j’ai commencé à communiquer sur mes projets à travers les réseaux sociaux et j’ai créé mon statut pour mon auto-entreprise. Les retours positifs des professionnels du mariage m’ont motivé et c’est à instant que j’ai su que j’étais faite pour ça. Mais une question subsiste : comment trouver mes clients ? J’ai une idée assez précise des personnes que je souhaite toucher mais il n’est pas facile d’être visible auprès d’eux. </p>
      
      <p>Même si je découvre au quotidien ce que c’est que d’être entrepreneur, je sais plutôt bien comment fonctionne un plan de communication. Pour accroître ma visibilité, il me faut un site internet performant et référencé. J’ai la chance de partager ma vie avec la personne qui a monté de A à Z mon site internet et gère mon référencement. Aujourd’hui, la création du blog me permets de vous partager mes conseils en matière de décoration de mariage, de vous parler de mes projets, de l’évolution de mon entreprise… En parallèle du site internet et de ma communication sur les réseaux sociaux, j’ai eu l’opportunité de participer au Salon du mariage de l’Eco-Domaine La Fontaine à Pornic afin de rencontrer des futurs mariés. Et vous savez quoi ? C’est dans ce magnifique lieu de réception que je vais réaliser la décoration de mariage de mes premiers clients en 2024.</p>
      <p>Bientôt, je vous en dirai plus sur ce premier mariage : les préparatifs, sa réalisation, le retour des mariés. Je vous parlerai aussi des prochaines étapes pour améliorer mon activité de Wedding Designer avec notamment la création de mon studio graphique pour imaginer et réaliser une papeterie élégante pour votre mariage et un showroom pour vous proposer plus de matériels et décoration à la location. Alors stay tuned ! </p>
    </div>
    </div>
  );
};

export default SecondArticleContent;