import image1 from '../images/gallery/yohann_2024-01-13_1436/ceremonielaique-mariagebohovintage-yohannfaupelphotography1.webp';
import image1min from '../images/gallery/yohann_2024-01-13_1436/ceremonielaique-mariagebohovintage-yohannfaupelphotography1Small.webp';

import image2 from '../images/gallery/yohann_2024-01-13_1436/ceremonielaique-mariagebohovintage-yohannfaupelphotography2.webp';
import image2min from '../images/gallery/yohann_2024-01-13_1436/ceremonielaique-mariagebohovintage-yohannfaupelphotography2Small.webp';

import image3 from '../images/gallery/yohann_2024-01-13_1436/cortege-mariagebohovintage-yohannfaupelphotography1.webp';
import image3min from '../images/gallery/yohann_2024-01-13_1436/cortege-mariagebohovintage-yohannfaupelphotography1Small.webp';

import image4 from '../images/gallery/yohann_2024-01-13_1436/cortege-mariagebohovintage-yohannfaupelphotography2.webp';
import image4min from '../images/gallery/yohann_2024-01-13_1436/cortege-mariagebohovintage-yohannfaupelphotography2Small.webp';

import image5 from '../images/gallery/yohann_2024-01-13_1436/cortege-mariagebohovintage-yohannfaupelphotography3.webp';
import image5min from '../images/gallery/yohann_2024-01-13_1436/cortege-mariagebohovintage-yohannfaupelphotography3Small.webp';

import image6 from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography1.webp';
import image6min from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography1Small.webp';

import image7 from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography2.webp';
import image7min from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography2Small.webp';

import image8 from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography3.webp';
import image8min from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography3Small.webp';

import image9 from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography5.webp';
import image9min from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography5Small.webp';

import image10 from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography6.webp';
import image10min from '../images/gallery/yohann_2024-01-13_1436/decorationreception-mariagebohovintage-yohannfaupelphotography6Small.webp';

import image11 from '../images/gallery/yohann_2024-01-13_1436/panneaudecoration-mariagebohovintage-yohannfaupelphotography1.webp';
import image11min from '../images/gallery/yohann_2024-01-13_1436/panneaudecoration-mariagebohovintage-yohannfaupelphotography1Small.webp';

import image12 from '../images/gallery/yohann_2024-01-13_1436/panneaudecoration-mariagebohovintage-yohannfaupelphotography2.webp';
import image12min from '../images/gallery/yohann_2024-01-13_1436/panneaudecoration-mariagebohovintage-yohannfaupelphotography2Small.webp';

import image13 from '../images/gallery/yohann_2024-01-13_1436/plandetable-mariagebohovintage-yohannfaupelphotography2.webp';
import image13min from '../images/gallery/yohann_2024-01-13_1436/plandetable-mariagebohovintage-yohannfaupelphotography2Small.webp';

import image14 from '../images/gallery/anais-site_2024-01-13_1317/bouquetmariee-elopementbohocoloré-anaisduchesnephotographie1.webp';
import image14min from '../images/gallery/anais-site_2024-01-13_1317/bouquetmariee-elopementbohocoloré-anaisduchesnephotographie1Small.webp';

import image15 from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie1.webp';
import image15min from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie1Small.webp';

import image16 from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie4.webp';
import image16min from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie4Small.webp';

import image17 from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie5.webp';
import image17min from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie5Small.webp';

import image18 from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie6.webp';
import image18min from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie6Small.webp';

import image19 from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie7.webp';
import image19min from '../images/gallery/anais-site_2024-01-13_1317/ceremonielaique-elopementbohocoloré-anaisduchesnephotographie7Small.webp';

import image20 from '../images/gallery/anais-site_2024-01-13_1317/espacelounge-elopementbohocoloré-anaisduchesnephotographie1.webp';
import image20min from '../images/gallery/anais-site_2024-01-13_1317/espacelounge-elopementbohocoloré-anaisduchesnephotographie1Small.webp';

import image21 from '../images/gallery/anais-site_2024-01-13_1317/espacelounge-elopementbohocoloré-anaisduchesnephotographie2.webp';
import image21min from '../images/gallery/anais-site_2024-01-13_1317/espacelounge-elopementbohocoloré-anaisduchesnephotographie2.webp';

import image22 from '../images/gallery/anais-site_2024-01-13_1317/espacelounge-elopementbohocoloré-anaisduchesnephotographie3.webp';
import image22min from '../images/gallery/anais-site_2024-01-13_1317/espacelounge-elopementbohocoloré-anaisduchesnephotographie3Small.webp';

import image23 from '../images/gallery/anais-site_2024-01-13_1317/mariee-elopementbohocoloré-anaisduchesnephotographie1.webp';
import image23min from '../images/gallery/anais-site_2024-01-13_1317/mariee-elopementbohocoloré-anaisduchesnephotographie1.webp';

import image24 from '../images/gallery/anais-site_2024-01-13_1317/papeterie-elopementbohocoloré-anaisduchesnephotographie1.webp';
import image24min from '../images/gallery/anais-site_2024-01-13_1317/papeterie-elopementbohocoloré-anaisduchesnephotographie1Small.webp';

import image25 from '../images/gallery/anais-site_2024-01-13_1317/papeterie-elopementbohocoloré-anaisduchesnephotographie2.webp';
import image25min from '../images/gallery/anais-site_2024-01-13_1317/papeterie-elopementbohocoloré-anaisduchesnephotographie2Small.webp';

import image26 from '../images/gallery/anais-site_2024-01-13_1317/tablemaries-elopementbohocoloré-anaisduchesnephotographie2.webp';
import image26min from '../images/gallery/anais-site_2024-01-13_1317/tablemaries-elopementbohocoloré-anaisduchesnephotographie2.webp';

import image27 from '../images/gallery/anais-site_2024-01-13_1317/weddingcake-elopementbohocoloré-anaisduchesnephotographie1.webp';
import image27min from '../images/gallery/anais-site_2024-01-13_1317/weddingcake-elopementbohocoloré-anaisduchesnephotographie1.webp';

import image28 from '../images/gallery/anais-site_2024-01-13_1317/weddingcake-elopementbohocoloré-anaisduchesnephotographie2.webp';
import image28min from '../images/gallery/anais-site_2024-01-13_1317/weddingcake-elopementbohocoloré-anaisduchesnephotographie2Small.webp';

import image29 from '../images/gallery/anais-site_2024-01-13_1317/weddingcake-elopementbohocoloré-anaisduchesnephotographie3.webp';
import image29min from '../images/gallery/anais-site_2024-01-13_1317/weddingcake-elopementbohocoloré-anaisduchesnephotographie3Small.webp';

import image30 from '../images/gallery/sarah_2024-01-11_1631/cocktail-mariagedolcevita-sarahduguephotographe50.webp';
import image30min from '../images/gallery/sarah_2024-01-11_1631/cocktail-mariagedolcevita-sarahduguephotographe50Small.webp';

import image31 from '../images/gallery/sarah_2024-01-11_1631/cocktail-mariagedolcevita-sarahduguephotographe85.webp';
import image31min from '../images/gallery/sarah_2024-01-11_1631/cocktail-mariagedolcevita-sarahduguephotographe85Small.webp';

import image32 from '../images/gallery/sarah_2024-01-11_1631/cocktail-mariagedolcevita-sarahduguephotographe88.webp';
import image32min from '../images/gallery/sarah_2024-01-11_1631/cocktail-mariagedolcevita-sarahduguephotographe88Small.webp';

import image33 from '../images/gallery/sarah_2024-01-11_1631/cortege-mariagedolcevita-sarahduguephotographe39.webp';
import image33min from '../images/gallery/sarah_2024-01-11_1631/cortege-mariagedolcevita-sarahduguephotographe39Small.webp';

import image34 from '../images/gallery/sarah_2024-01-11_1631/cortege-mariagedolcevita-sarahduguephotographe40.webp';
import image34min from '../images/gallery/sarah_2024-01-11_1631/cortege-mariagedolcevita-sarahduguephotographe40Small.webp';

import image35 from '../images/gallery/sarah_2024-01-11_1631/decorationreception-mariagedolcevita-sarahduguephotographe19.webp';
import image35min from '../images/gallery/sarah_2024-01-11_1631/decorationreception-mariagedolcevita-sarahduguephotographe19Small.webp';

import image36 from '../images/gallery/sarah_2024-01-11_1631/decorationreception-mariagedolcevita-sarahduguephotographe3.webp';
import image36min from '../images/gallery/sarah_2024-01-11_1631/decorationreception-mariagedolcevita-sarahduguephotographe3Small.webp';

import image37 from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe133.webp';
import image37min from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe133Small.webp';

import image38 from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe15.webp';
import image38min from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe15Small.webp';

import image39 from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe160.webp';
import image39min from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe160Small.webp';

import image40 from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe84.webp';
import image40min from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe84Small.webp';

import image41 from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe89.webp';
import image41min from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe89Small.webp';

import image42 from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe95.webp';
import image42min from '../images/gallery/sarah_2024-01-11_1631/decorationtable-mariagedolcevita-sarahduguephotographe95Small.webp';

import image43 from '../images/gallery/sarah_2024-01-11_1631/weddingcakeshootingdolcevita-sarahduguephotographe128.webp';
import image43min from '../images/gallery/sarah_2024-01-11_1631/weddingcakeshootingdolcevita-sarahduguephotographe128Small.webp';

import image44 from '../images/gallery/sarah_2024-01-11_1631/weddingcakeshootingdolcevita-sarahduguephotographe17.webp';
import image44min from '../images/gallery/sarah_2024-01-11_1631/weddingcakeshootingdolcevita-sarahduguephotographe17Small.webp';

import image45 from '../images/gallery/sarah_2024-01-11_1631/weddingcakeshootingdolcevita-sarahduguephotographe20.webp';
import image45min from '../images/gallery/sarah_2024-01-11_1631/weddingcakeshootingdolcevita-sarahduguephotographe20Small.webp';

import image46 from '../images/gallery/sarah_2024-01-11_1631/weddingcakeshootingdolcevita-sarahduguephotographe27.webp';
import image46min from '../images/gallery/sarah_2024-01-11_1631/weddingcakeshootingdolcevita-sarahduguephotographe27Small.webp';


// Définition de la constante IMAGES
const IMAGES = [
  {
    id: 1,
    src: image1,
    minSrc : image1min,
    caption: "Scénographie de votre cérémonie laïque bohème",
    credit: "Yohann Faupel Photographe",
    category: "Cérémonie laïque",
    format : "Paysage"
  },
  {
    id: 2,
    src: image2,
    minSrc : image2min,
    caption: "Une composition florale bohème pour votre arche de cérémonie",
    credit: "Yohann Faupel Photographe",
    category: "Cérémonie laïque",
    format : "Portrait"
  },
  {
    id: 3,
    src: image3,
    minSrc : image3min,
    caption: "Une peigne floral bohème pour agrémenter votre coiffure de mariée",
    credit: "Yohann Faupel Photographe",
    category: "Décor floral",
    format : "Paysage"
  },
  {
    id: 4,
    src: image4,
    minSrc : image4min,
    caption: "Un bouquet romantique et élégant pour votre mariage",
    credit: "Yohann Faupel Photographe",
    category: "Décor floral",
    format : "Portrait"
  },
  {
    id: 5,
    src: image5,
    minSrc : image5min,
    caption: "Un boutonnière romantique et élégant qui matche avec le bouquet de la mariée",
    credit: "Yohann Faupel Photographe",
    category: "Décor floral",
    format : "Paysage"
  },
  {
    id: 6,
    src: image6,
    minSrc : image6min,
    caption: "Un marque-place personnalisé sur le thème du voyage version vintage",
    credit: "Yohann Faupel Photographe",
    category: "Salle réception",
    category: "Papeterie",
    format : "Paysage"
  },
  {
    id: 7,
    src: image7,
    minSrc : image7min,
    caption: "Une pancarte personnalisée pour votre nom de table à piquer dans un bouquet",
    category: "Salle réception",
    category: "Papeterie & Signalétique",
    format : "Portrait"
  },
  {
    id: 8,
    src: image8,
    minSrc : image8min,
    caption: "Des soliflores et bouquets dépareillés pour votre décoration de table bohème",
    credit: "Yohann Faupel Photographe",
    category: "Décor floral",
    category: "Salle réception",
    format : "Portrait"
  },
  {
    id: 9,
    src: image9,
    minSrc : image9min,
    caption: "Un menu par table personnalisé selon votre thème ou ambiance de mariage",
    credit: "Yohann Faupel Photographe",
    category: "Décor floral",
    category: "Papeterie & Signalétique",
    format : "Portrait"
  },
  {
    id: 10,
    src: image10,
    minSrc : image10min,
    caption: "Une succession de bougeoirs et soliflores dépareillés pour votre chemin de table",
    credit: "Yohann Faupel Photographe",
    category: "Salle réception",
    format : "Paysage"
  },

  {
    id: 11,
    src: image11,
    minSrc : image11min,
    caption: "Un panneau de bienvenue personnalisé pour une ambiance bohème et vintage",
    credit: "Yohann Faupel Photographe",
    
    category: "Salle réception",
    category: "Papeterie & Signalétique",
    format : "Portrait"
  },
  {
    id: 12,
    src: image12,
    minSrc : image12min,
    caption: "Un panneau décoratif pour diriger les invités lors de votre réception de mariage",
    credit: "Yohann Faupel Photographe",
    category: "Papeterie & Signalétique",
    format : "Portrait"
  },
  {
    id: 13,
    src: image13,
    minSrc : image13min,
    caption: "Zoom sur un plan de table original pour un thème voyage",
    credit: "Yohann Faupel Photographe",
    category: "Salle réception",
    category: "Papeterie & Signalétique",
    format : "Paysage"
  },


  {
    id: 14,
    src: image14,
    minSrc : image14min,
    caption: "Un bouquet de mariée coloré entre dahlias et cosmos",
    category: "Décor floral",
    credit: "Anaïs Duchesne Photographe",
    format : "Portrait"
  },
  {
    id: 15,
    src: image15,
    minSrc : image15min,
    caption: "Une arche de cérémonie laïque pour votre mariage à la plage",
    category: "Cérémonie laïque",
    category: "Décor floral",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 16,
    src: image16,
    minSrc : image16min,
    caption: "Les voeux des mariés lors d’un elopement à la plage",
    category: "Cérémonie laïque",
    category: "Photobooth & Espace Lounge",
    credit: "Anaïs Duchesne Photographe",
    format : "Portrait"
  },
  {
    id: 17,
    src: image17,
    minSrc : image17min,
    caption: "Des chaises de cérémonie laïque originale pour un mariage chaleureux",
    category: "Décor floral",
    category: "Cérémonie laïque",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 18,
    src: image18,
    minSrc : image18min,
    caption: "Une cérémonie laïque bohème et colorée sur la plage",
    category: "Cérémonie laïque",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 19,
    src: image19,
    minSrc : image19min,
    caption: "L’échange des alliances lors de la cérémonie laïque",
    category: "Cérémonie laïque",
    credit: "Anaïs Duchesne Photographe",
    format : "Portrait"
  },
  {
    id: 20,
    src: image20,
    minSrc : image20min,
    caption: "Un espace détente pour échapper à l’effervescence de la réception",
    category: "Photobooth & Espace Lounge",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 21,
    src: image21,
    minSrc : image21min,
    caption: "Des petites table en osier au style bohème pour votre espace détente",
    category: "Décor floral",
    category: "Photobooth & Espace Lounge",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 22,
    src: image22,
    minSrc : image22min,
    caption: "Un espace détente afin de profiter du calme avec votre partenaire",
    category: "Photobooth & Espace Lounge",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  }
  ,  {
    id: 23,
    src: image23,
    minSrc : image23min,
    caption: "Une broche florale pour accessoiriser votre coiffure de mariée",
    category: "Décor floral",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 24,
    src: image24,
    minSrc : image24min,
    caption: "Une papeterie originale et colorée pour votre mariage",
    category: "Papeterie & Signalétique",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 25,
    src: image25,
    minSrc : image25min,
    caption: "Zoom sur votre papeterie de mariage, élégante et colorée",
    category: "Papeterie & Signalétique",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 26,
    src: image26,
    minSrc : image26min,
    caption: "Un dîner aux chandelles colorées face à la mer",
    category: "Salle réception",
    category: "Décor floral",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 27,
    src: image27,
    minSrc : image27min,
    caption: "Une wedding cake délicieux et coloré assorti à votre décoration",
    category: "Bar à thème & wedding cake",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 28,
    src: image28,
    minSrc : image28min,
    caption: "Trinquez et dégustez votre wedding cake avec ou sans invités",
    category: "Bar à thème & wedding cake",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },
  {
    id: 29,
    src: image29,
    minSrc : image29min,
    caption: "C’est le moment de déguster votre délicieux gâteau de mariage",
    category: "Bar à thème & wedding cake",
    credit: "Anaïs Duchesne Photographe",
    format : "Paysage"
  },


  {
    id: 30,
    src: image30,
    minSrc : image30min,
    caption: "Un bar à cocktail décoré et animé par un professionnel ",
    category: "Bar à thème & wedding cake",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 31,
    src: image31,
    minSrc : image31min,
    caption: "Un bar en palette pour une ambiance bohème durant votre cocktail",
    category: "Bar à thème & wedding cake",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 32,
    src: image32,
    minSrc : image32min,
    caption: "Composition florale citronnée pour habiller votre bar à thème",
    category: "Décor floral",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 33,
    src: image33,
    minSrc : image33min,
    caption: "Bouquets de la mariée et demoiselles d’honneur assortis",
    category: "Décor floral",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 34,
    src: image34,
    minSrc : image34min,
    caption: "Bouquet de mariée en cascade",
    category: "Décor floral",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 35,
    src: image35,
    minSrc : image35min,
    caption: "Photobooth pour immortaliser votre mariage avec vos invités",
    category: "Photobooth & Espace Lounge",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 36,
    src: image36,
    minSrc : image36min,
    caption: "Composition florale et citrons pour une ambiance dolce vita",
    category: "Décor floral",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 37,
    src: image37,
    minSrc : image37min,
    caption: "Une ambiance dolce vita pour votre décoration de table",
    category: "Salle réception",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 38,
    src: image38,
    minSrc : image38min,
    caption: "Soliflore pour décorer vos tables de mariage",
    category: "Salle réception",
    category: "Décor floral",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 39,
    src: image39,
    minSrc : image39min,
    caption: "Une ambiance dolce vita pour votre décoration de mariage",
    category: "Salle réception",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 40,
    src: image40,
    minSrc : image40min,
    caption: "Table des mariés et suspensions végétales",
    category: "Salle réception",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },

  {
    id: 41,
    src: image41,
    minSrc : image41min,
    caption: "Décoration de table dans une ambiance dolce vita",
    category: "Salle réception",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 42,
    src: image42,
    minSrc : image42min,
    caption: "Chemin de table avec succession de soliflores",
    category: "Salle réception",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 43,
    src: image43,
    minSrc : image43min,
    caption: "Un espace wedding cake personnalisé selon votre thème",
    category: "Bar à thème & wedding cake",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 44,
    src: image44,
    minSrc : image44min,
    caption: "Biscuits personnalisés aux allures citronnées pour votre wedding cake",
    category: "Bar à thème & wedding cake",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 45,
    src: image45,
    minSrc : image45min,
    caption: "Gâteau personnalisé selon votre thème ou ambiance de mariage",
    category: "Bar à thème & wedding cake",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  },
  {
    id: 46,
    src: image46,
    minSrc : image46min,
    caption: "Composition florale avec néon lumineux pour customiser vos espaces",
    category: "Décor floral",
    category: "Papeterie & Signalétique",
    credit: "Sarah Dugué Photographe",
    format : "Paysage"
  }
];

export default IMAGES;