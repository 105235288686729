import React from 'react';
import "../css-styles/ArticleContent.scss";
import { Link } from 'react-router-dom';
import Image1 from '../images/Articles/article 2/Ceremonie Laique plage couple.jpg';
import Image2 from '../images/Articles/article 2/Ceremonie laique plage.jpeg';
import Image3 from '../images/Articles/article 2/decors table.jpeg';
import Image4 from '../images/Articles/article 2/Bar wedding.jpeg';
import Image5 from '../images/Articles/article 2/couple marie weddingcake.jpeg';


const ArticleContent = () => {
  return (
    <div className='wrapper-article'>
      <img src={Image1} className='photo-long-banner'/>
      <div className="article-content">
      <h1>Le métier de Wedding Designer, c’est quoi ?</h1>
      <h2>Découvrons ensemble le rôle d’une décoratrice de mariage</h2>
      <p>Wedding planner, wedding designer, wedding officiant… pas facile de s’y retrouver quand on débarque dans l’univers du mariage, pas vrai ? Alors commençons par une définition toute simple : une wedding designer est une professionnelle spécialisée dans la conception et la planification artistique et esthétique d'événements autour du mariage.</p>
      
      <h3>Quelles sont les missions d’une décoratrice ou scénographe de mariage ?</h3>
      <p>Son rôle principal est de créer une expérience visuelle qui soit cohérente avec vos attentes et marque le plus beau jour de votre vie. Pour créer une véritable harmonie dans la scénographie, elle prend en considération les différents éléments de la décoration, des éclairages, du design floral, de la papeterie et d’autres aspects esthétiques en lien avec votre mariage.</p>
      <p>Il faut savoir analyser les tendances mais aussi savoir associer les couleurs, les formes et les matières ensemble. Pour visualiser cette ambiance, la wedding designer vous propose un moodboard ou planche d’inspiration. C’est le point de départ de votre décoration de mariage.</p>
      <p>Pour imaginer votre décoration de mariage, elle travaille en collaboration avec vous tout au long des préparatifs, pour comprendre vos envies, votre style et connaître les traits importants de vos personnalités afin de vous proposer une ambiance visuelle concrète et pleine de sens pour votre mariage. Elle vous conseille et vous accompagne pour choisir vos fleurs, votre papeterie et les éléments relatifs à la décoration : objets, mobiliers, tissus, lumières etc.<br>
      </br> Ces éléments lui permettent de créer un book déco complet et personnalisé qui sera aussi le document fil rouge de votre décoration de mariage. Le book permet de visualiser tous vos espaces décorés, les objets, les matières, les dimensions et les quantités utilisés. </p>
      <img src={Image2} className='photo-banner'/>
      <p>Au delà de l’aspect créatif, la coordination et la logistique sont des compétences importantes pour exercer le métier de wedding designer. En effet, la décoratrice de mariage fait appel à un réseau de partenaires qualifiés pour réaliser la scénographie de votre réception. Les prestataires sont sélectionnés sur plusieurs critères : le style, le savoir-faire, la disponibilité et le budget notamment. Ainsi, votre décoratrice vous propose les partenaires les plus adaptés à vos envies pour les fleurs, la papeterie, le mobilier, la vaisselle, la décoration et tout autre chose qui aurait attrait à l’esthétisme de votre mariage.<br>
      </br> Elle s’assure ensuite de la validation, du suivi, des modifications à apporter et de la création finale auprès de ses partenaires jusqu’à l’installation de votre décoration de mariage (et la désinstallation). C’est une  véritable chef d’orchestre !</p>
      
      <h3>Quelle est la différence entre une wedding designer et une wedding planner ?</h3>
      <p>La notion de wedding designer est assez récente dans l’univers du mariage, c’est pourquoi il est encore facile de confondre les deux. Si la wedding designer se focalise plutôt sur l’aspect visuel et esthétique du mariage et gère la coordination logistique des prestataires qui participent à cette vision, la wedding planner s’occupe quant à elle de l’organisation du mariage dans sa globalité. Elle gère notamment la coordination des  prestataires suivants : lieu de réception, traiteur, photographe et vidéaste, DJ ou orchestre. C’est un travail en amont, pendant et après votre mariage. Elle aussi, recherche et vous propose les prestations les plus adaptées à vos attentes.</p>
      <p>Si vous souhaitez une véritable ambiance pour votre décoration de mariage, il est mieux de faire appel à une wedding designer car celle-ci se concentrera uniquement sur cet objectif. C’est une décoratrice, une scénographe et son rôle est de sublimer votre cérémonie et réception de mariage. La wedding planner a un rôle plus général et tout aussi important : c’est une organisatrice qui facilitent la planification et la coordination de votre mariage. Chaque métier demande une implication complète dans les tâches à accomplir avec toute fois un objectif commun : vous rendre l’organisation et la décoration de votre mariage plus facile !</p>
      <p>Vous l’aurez compris, ce sont donc deux métiers qui ont leurs propres spécificités mais qui peuvent être complémentaires. Si vous souhaitez confier l’organisation générale de votre mariage à une professionnelle, des préparatifs au jour J, faites appel à une wedding planner. Si vous souhaitez mettre un point d’honneur à rendre cette journée inoubliable avec la décoration de mariage de vos rêves, faites plutôt appel à une wedding designer. Enfin, si vous souhaitez déléguer complètement l’organisation et la scénographie de votre mariage, alors il est important de faire appel à une wedding planner ET une wedding designer pour une expérience encore plus incroyable !</p>
      
      <h3>Quand ai-je besoin de faire appel à une wedding designer ?</h3>
      <p>Vous êtes des futurs mariés et vous avez envie de créer un véritable univers pour votre mariage mais vous êtes perdus sur Pinterest… Il y a tellement de possibilités que vous n’arrivez pas à choisir un thème ou une ambiance pour votre réception. Une wedding designer va s’intéresser à vos attentes, vos goûts, vos personnalités et vous poser les bonnes questions. Ensemble, vous pourrez ainsi faire apparaître un thème ou une ambiance et décliner votre décoration de mariage autour de ça. Cette formule s’apparente à du coaching, cela vous permet d’être aiguiller vers les bons prestataires et de créer vous-même le décor de vos rêves. </p>
      <div className='Wrapper-photo-banner'>
        <img src={Image3} className='multiple-photo-banner'/>
      <img src={Image4} className='multiple-photo-banner'/>
      <img src={Image5} className='multiple-photo-banner'/>
      </div>
      
      <p>Vous souhaitez une décoration de mariage sophistiquée pour habiller votre lieu de réception mais vous êtes débordés par l’ensemble de vos préparatifs… il est peut-être temps de déléguer et de faire confiance à une professionnelle de la scénographie !<br>
      </br> Une wedding designer prendra le relais sur tous les aspects esthétiques et créatifs de votre mariage. Son expertise et son réseau de partenaires déco (fleurs, papeterie, lumières, mobiliers et décoration…) vous fera gagner un temps précieux et vous permettra de vous focaliser sur le reste de vos préparatifs. Cette formule est adaptée à vos besoins si vous souhaitez installer votre décoration de mariage vous-même la veille et le jour J.</p>
      <p>Les préparatifs de votre mariage vous prennent un temps fou et vous rêvez de profiter pleinement du grand jour en compagnie de vos proches et ne souhaitez pas passer plusieurs heures à installer votre décoration de mariage vous-même. Là encore, la wedding designer s’occupe de tout. Décoratrice ET scénographe, chaque décor sera pensé dans les moindres détails en amont de votre mariage, chaque prestataire sera choisi selon vos envies, chaque décor sera pensé et imaginé pour vous. Et surtout, l’installation de votre décoration de mariage sera faite avec professionnalisme et beaucoup d’amour ! C’est une véritable expérience visuelle qui vous attends pour votre mariage si vous vous laissez tenter par une formule sur-mesure.</p>
      
      <p>En tant que wedding designer, Le Labo’ Inspi vous propose une décoration de mariage naturelle, romantique et élégante avec une touche d’originalité. Je prends soin de découvrir mes futurs mariés et de prendre en considération toutes leurs attentes à propos de l’ambiance qui souhaitent donner à leur mariage. Ensemble, nous travaillons pour vous offrir une expérience unique qui vous ressemble.</p>
      <p>Pour plus d’informations sur mes formules, <Link to={'/Coaching'}>cliquez ici</Link>.</p>
    </div>
    </div>
    
  );
};

export default ArticleContent;