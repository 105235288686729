import React from "react";
import  '../css-styles/FormuleMessage.scss';

const FormuleMessage = () => {

  return (
    <div className="formule-message-info">
          <p>Si vous êtes intéressé ou souhaitez plus d'informations à propos de cette formule</p>
        </div>
  );
};

export default FormuleMessage;