import React from "react";
import { useEffect } from "react";
import PrepareWedding from "../components/PrepareWedding";
import Header from "../components/Header";
import FormuleNav from "../components/FormuleNav";
import Footer from "../components/Footer";
import MyFormModal from "../components/MyFormModal";
import FormuleMessage from "../components/FormuleMessage";
import "../css-styles/Responsive.scss"
import { Helmet } from 'react-helmet';

const SecondeFormule = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home">
      <div className="home-container">
      <Helmet>
        <title>Formule & tarif préparatifs mariage</title>
        <meta name="description" content="Laissez Le Labo' Inspi alléger le stress de la préparation de votre mariage avec notre Formule Préparatifs. Description complète de la prestation de mariage et des tarifs"></meta>
        <link rel="canonical" href="https://laboinspi-wedding.fr/Preparatifs" />
      </Helmet>
        <Header />
        <PrepareWedding />
        <FormuleMessage/>
        <MyFormModal/>
        <FormuleNav />
    </div>
    <Footer />
    </div>
    
  );
};

export default SecondeFormule;
