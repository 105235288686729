import React, { useState } from 'react';
import IMAGES from './ImageGallery'; // Importez IMAGES comme exportation par défaut
import '../css-styles/GalerieComponent.scss';


const GalerieComponent = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Toutes');

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  // Fonction pour filtrer les images selon la catégorie sélectionnée
  const getFilteredImages = () => {
    if (selectedCategory === 'Toutes') {
      return IMAGES;
    }
    return IMAGES.filter(image => image.category === selectedCategory);
  };

  return (
    <div>
      {/* Sélecteur de catégories */}
      <div className="category-selector">
        {['Toutes', 'Salle réception', 'Décor floral', 'Papeterie & Signalétique', 'Cérémonie laïque', 'Bar à thème & wedding cake','Photobooth & Espace Lounge'].map(category => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={selectedCategory === category ? "active" : ""}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Affichage des images */}
      <div className="gallery">
        {getFilteredImages().map((image) => (
          <div key={image.id} className="gallery-item">
            <img
              src={image.minSrc}
              alt={image.caption}
              onClick={() => openImage(image)}
              style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
              loading="lazy" // Ajout de l'attribut loading="lazy"
            />
          </div>
        ))}
      </div>

      {/* Modal pour l'image sélectionnée */}
      {selectedImage && (
        <div className="image-modal" onClick={closeImage}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <img src={selectedImage.src} alt={selectedImage.caption} />
            <p>{selectedImage.caption}</p>
            <p>{selectedImage.credit}</p>
            <button onClick={closeImage}>X</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GalerieComponent;