import React from "react";
import { useEffect } from "react";
import PreviewFormules from "../components/PreviewFormules"; // Assurez-vous de corriger le chemin d'accès

import InspirationWedding from "../components/InspirationWedding";
import Header from "../components/Header";
import Footer from "../components/Footer";
import FormuleNav from "../components/FormuleNav";
import MyFormModal from "../components/MyFormModal";
import FormuleMessage from "../components/FormuleMessage";
import '../css-styles/Responsive.scss';
import { Helmet } from 'react-helmet';


const FirstFormule = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home">
      <div className="home-container">
      <Helmet>
        <title>Formule & tarif coaching mariage</title>
        <meta name="description" content="Offrez-vous un accompagnement personnalisé avec la Formule Coaching 'Inspiration & Déco' du Labo' Inspi. Description complète de la prestation de mariage et des tarifs"/>

        <link rel="canonical" href="https://laboinspi-wedding.fr/Coaching" />
      </Helmet>
        <Header />
        <InspirationWedding />
       <FormuleMessage/>
        <MyFormModal/>
        <FormuleNav />
      
    </div>
    <Footer />
    </div>
    
  );
};

export default FirstFormule;
