import React from "react";
import Header from "../components/Header.js";
import "../css-styles/Apropos.scss";
import "../css-styles/Home.scss";
import MyFormModal from "../components/MyFormModal.js";
import Formulaire from "../components/Formulaire.js";
import image from "../images/marinephilippeweddingdesigner-morganecaullephotographie.jpeg";
import MyMapComponent from "../components/MyMapComponent.js";
import Footer from "../components/Footer.js";
import { Helmet } from "react-helmet";

const Apropos = () => {
  return (
    <div className="home">
      <div className="home-container">
        <Helmet>
          <title>A propos de wedding designer</title>
          <meta
            name="description"
            content="Créatrice de décors de mariage sur mesure en Loire-Atlantique, je conçois des ambiances et scénographies unique pour rendre votre jour spécial inoubliable."
          />
          <link rel="canonical" href="https://laboinspi-wedding.fr/Apropos" />
        </Helmet>
        <Header />

        <div className="Background-about">
          <h1> À qui confiez-vous votre décoration de mariage ?</h1>
          <h2>
            Une scénographe et créatrice de souvenirs installée en
            Loire-Atlantique
          </h2>
          <div className="about-wrapper">
            <div className="about-container">
              <div className="Bio-text">
                <p>
                  Je m’appelle Marine, j’ai grandi en Normandie et je me suis
                  toujours sentie chez moi en me baladant au bord de la mer.
                  Débarquée à Saint-Nazaire pour le travail, je suis tombée
                  amoureuse des environs : Guérande, Pornic, Nantes et ses
                  vignobles… <br></br>Une nature luxuriante qui a le pouvoir de
                  réveiller mon esprit créatif !
                </p>
                <p>
                  Passionnée de décoration mais aussi romantique dans l’âme,
                  j’ai toujours adoré créer des décors pour toutes les
                  occasions.<br></br>D’abord, étudiante en communication et
                  événementiel, je me suis spécialisée dans l’organisation
                  d’événements professionnels et j’ai adoré. Seulement, le côté
                  créatif me manquait.
                </p>
                <p>
                  {" "}
                  Cette aventure de wedding designer, c’est donc un mélange bien
                  dosé de mes compétences d’organisatrice d’événements et ma
                  passion pour la décoration.
                </p>
              </div>
              <div className="findMe-text">
                <h3 className="element-title">
                  {" "}
                  Ma zone d’intervention en tant que décoratrice de mariage
                </h3>
                <p>
                  Située entre Pornic et Nantes, je vous propose mes conseils et
                  mon accompagnement personnalisé pour décorer vos événements
                  dans tout le département de Loire-Atlantique (44) et la Vendée
                  (85).
                </p>
              </div>
              <MyMapComponent />
            </div>
            <img
              className="imageAbout-background"
              src={image}
              alt="Marine Philippe - Wedding designer Pornic"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Apropos;
