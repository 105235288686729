import React, { useEffect } from 'react';
import image2 from '../images/Separateur.png';
import '../css-styles/Title.scss';
import Image1 from '../images/slider/image2.webp';
import ImageSmall from '../images/slider/image2_small.webp';

const Title = () => {
    useEffect(() => {
        const img = new Image();
        img.src = ImageSmall;
    }, []);

    return (
        <div>
            <div className='hero-background'>
                <img src={window.innerWidth <= 576 ? ImageSmall : Image1} className="slider-photographie" alt="photographie-couple-mariage-dolcevita" loading="eager" />
            </div>
            <div className='title-wrapper'>
                <h2 className='signature-wordkey'>Wedding & Event Designer</h2>
                <h1 className='title-main'>Organisation et décoration pour vos événements</h1>
                <h3 className='title-second'>Concevons ensemble le mariage de vos rêves</h3>
                <img src={image2} className="separator" alt="séparateur-texte"/>
            </div>     
        </div>
    );
}

export default Title;